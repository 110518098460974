import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';
import { CarritoService } from '../../services/carrito.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  apartados:any = ['redondo', 'como-comprar', 'blog'];
  legales:any = ['aviso-legal', 'politica-de-privacidad', 'politica-de-cookies'];
  documentos: any = [{'link': 'politica-inocuidad-link', 'name': 'politica-inocuidad'}, {'link': 'politica-calidad-link', 'name': 'politica-calidad'}]
  categorias:any = [];
  categoriasPrincipales:any = [];

  constructor(private carritoService:CarritoService, private _datosService:DatosService) {
  }

  ngOnInit() {


      //Cargamos las categorias...
      this._datosService.getCategorias().subscribe(datos => {
        this.categorias = datos;
        //ORDENO POR POSITION
        this.categorias.sort((val1, val2)=> {return val2.position - val1.position});

        //filtro por las principales...
        this.categoriasPrincipales =  this.categorias.filter((categoria)=> categoria.owner_id == 0 );
      });
  }

}
