import { Component, OnInit, HostListener, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DatosService } from '../../services/datos.service';
import { CarritoService } from '../../services/carrito.service';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { Location } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from 'ng2-translate';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html'
})
export class ProductoComponent implements OnInit {

  rutaProducto: string = '';
  idProducto: string = '';
  producto: any = [];
  productos: any = [];


  productosFiltrados: any = [];
  productosRelacionados: any = [];
  unidades: number = 1;

  vistaApartado: boolean = false;



  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _datosService: DatosService,
    private carritoService: CarritoService,
    private formBuilder: FormBuilder,
    private location: Location,
    private translate: TranslateService,
    private meta: Meta
  ) { }

  ngOnInit() {
    this._datosService.ponSeccion('producto');
    this.unidades = 1;
    this.vistaApartado = false;
    this._datosService.ponSeccion('producto');
    this.activatedRoute.url.subscribe(url => {
      this.vistaApartado = false;
      if (this.activatedRoute.snapshot.url.length > 1) {
        this.rutaProducto = this.activatedRoute.snapshot.url[1].path;

        if (this.rutaProducto == 'organic-extra-light-and-special-light-cane-sugar' || this.rutaProducto == 'sucre-de-canne-biologique-mi-blanc-et-roux') {
          this.idProducto = 'azucar-cana-biologico';
        }
        if (this.rutaProducto == 'organic-golden-light-cane-sugar' || this.rutaProducto == 'sucre-de-canne-biologique-golden-light') {
          this.idProducto = 'golden-light';
        }
        if (this.rutaProducto == 'organic-whole-cane-sugar' || this.rutaProducto == 'sucre-de-canne-biologique-complet') {
          this.idProducto = 'complet';
        }
        if (this.rutaProducto == 'organic-white-cane-sugar' || this.rutaProducto == 'sucre-de-canne-biologique-blanc') {
          this.idProducto = 'blanco';
        }
        if (this.rutaProducto == 'organic-icing-cane-sugar' || this.rutaProducto == 'sucre-de-canne-biologique-glace') {
          this.idProducto = 'glace';
        }
        if (this.rutaProducto == 'organic-liquid-cane-sugar' || this.rutaProducto == 'sucre-de-canne-biologique-liquide') {
          this.idProducto = 'liquido';
        }
        if (this.rutaProducto == 'organic-inverted-cane-sugar' || this.rutaProducto == 'sucre-de-canne-biologique-inverti') {
          this.idProducto = 'invertido';
        }
        if (this.rutaProducto == 'organic-cane-molasses' || this.rutaProducto == 'melasse-de-canne-biologique') {
          this.idProducto = 'melaza';
        }

        if (this.rutaProducto == 'organic-rectified-concentrated-must' || this.rutaProducto == 'mout-concentre-rectifie-biologique') {
          this.idProducto = 'mosto';
        }

        if (this.rutaProducto == 'organic-coconut-sugar' || this.rutaProducto == 'sucre-de-coco-biologique') {
          this.idProducto = 'coco';
        }

        this.productos = this._datosService.productos;
        console.log(this.rutaProducto);
        console.log(this._datosService.productos);

        this.producto = this.productos.find((pro) => pro.nombre === this.idProducto);

        this.sacaApartado();
        /*
        if(this._datosService.getIdioma() == 'en' ) {
          this.nombreProducto = this.producto['nombre_en'];
          this.descripcionProducto = this.producto['descripcion_en'];
        }
        else {
          this.nombreProducto = this.producto['nombre'];
          this.descripcionProducto = this.producto['descripcion'];
        }
        this.meta.updateTag({ name: 'title', content: 'Redondo Charcutería Carnicería | ' + this.nombreProducto});
        this.meta.updateTag({ name: 'description', content: this.descripcionProducto});
        this.sacaApartado();
        */
      }
    }
    );
  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 500);
  }

  isOferta(producto) {
    if (producto.precio > producto.precio_rebajado) {
      return true;
    }
    else {
      return false;
    }
  }

  isActivo(producto) {
    if (!producto.fuera_de_temporada && !producto.en_reposicion) {
      return true;
    }
    else {
      return false;
    }
  }




  /**
   * addProducto al carrito
   */
  addProducto(producto) {
    this.carritoService.checkProducto(producto, this.unidades);
  }

  volver() {
    this.location.back();
  }


}
