import { Routes, RouterModule } from '@angular/router';


import { HomeComponent} from '../app/components/home/home.component';
import { NosotrosComponent} from '../app/components/nosotros/nosotros.component';
import { ProductosComponent} from '../app/components/productos/productos.component';
import { ProductoComponent} from '../app/components/producto/producto.component';
import { OrigenComponent} from '../app/components/origen/origen.component';
import { CalidadComponent} from '../app/components/calidad/calidad.component';
import { ContactoComponent} from '../app/components/contacto/contacto.component';
import { AvisoComponent} from '../app/components/aviso/aviso.component';
import { CarritoComponent} from '../app/components/carrito/carrito.component';
import { CheckoutComponent}  from "../app/components/checkout/checkout.component";
import { LoginComponent}  from "../app/components/login/login.component";
import { RespuestaComponent}  from "../app/components/respuesta/respuesta.component";
import { RecuperarComponent}  from "../app/components/recuperar/recuperar.component";
import { AuthGuard } from '../app/guards/auth.guards';

const app_routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'about-us', component: NosotrosComponent },
  { path: 'entreprise', component: NosotrosComponent },
  { path: 'products', component: ProductosComponent },
  { path: 'produits', component: ProductosComponent },
  { path: 'product/:id', component: ProductoComponent },

  //{ path: 'contacto', component: ContactoComponent },
  { path: 'origin', component: OrigenComponent },
  { path: 'origine', component: OrigenComponent },
  { path: 'quality', component: CalidadComponent },
  { path: 'qualite', component: CalidadComponent },
  { path: 'aviso-legal', component: AvisoComponent },
  { path: 'politica-de-privacidad', component: AvisoComponent },
  { path: 'politica-de-cookies', component: AvisoComponent },
  { path: '**', pathMatch: 'full', redirectTo: 'home' }
];


export const app_routing = RouterModule.forRoot(app_routes);
