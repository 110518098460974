import { Component, OnInit, HostListener, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Router, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';
import { DatosService } from '../../services/datos.service';
import { CarritoService } from '../../services/carrito.service';
import { TranslateService } from 'ng2-translate';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})


export class HeaderComponent implements OnInit {

  fixed:boolean = false;
  apartados:any = ['nosotros', 'origen', 'productos', 'calidad'];
  apartadosTodos:any = ['tienda', 'redondo', 'contacto', 'blog', 'como-comprar'];
  apartadosSecundarios:any = ['aviso-legal', 'politica-de-privacidad', 'politica-de-cookies'];
  numProductos:number;
  currentLang:any = '';
  categorias:any = [];
  categoriasPrincipales:any = [];
  rutaCategoria: string = '';

  isScrolled:boolean = false;
  scrollLimit:number =  0;


  constructor(
    private router: Router,
    private _datosService:DatosService,
    private translate: TranslateService, private carritoService:CarritoService, private el: ElementRef, private renderer: Renderer2) {
  }


@HostListener("window:scroll", [])
    onWindowScroll() {
        this.scrollLimit = 0;
        //this.scrollLimit = this.cabecera.nativeElement.offsetTop - 100;
        if(window.pageYOffset > this.scrollLimit) {
          this.isScrolled = true;
          this._datosService.globalScroll = true;
        }
        else {

          this.isScrolled = false;
          this._datosService.globalScroll = false;
        }
}


  ngOnInit() {
    this.carritoService.getCarrito().subscribe(data => {
      this.numProductos = this.carritoService.getTotalProductos();
    },
      error => alert(error));

      //Cargamos las categorias...
      this._datosService.getCategorias().subscribe(datos => {
        this.categorias = datos;
        //ORDENO POR POSITION
        this.categorias.sort((val1, val2)=> {return val2.position - val1.position});

        //filtro por las principales...
        this.categoriasPrincipales =  this.categorias.filter((categoria)=> categoria.owner_id == 0 );
      });
  }

  getLang() {
    if(this.currentLang) {
      return this.currentLang;
    }
    else{
      return this.translate.getBrowserLang();
    }
  }



}
