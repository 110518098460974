import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule, MatCheckboxModule, MatToolbarModule, MatCardModule,
  MatExpansionModule, MatDatepickerModule, MatInputModule, MatTabsModule,
  MatTooltipModule, MatFormFieldModule, MatMenuModule, MatSnackBarModule, MatSelectModule, MatRadioModule, MatListModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Http, HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';
import { LocationStrategy, HashLocationStrategy, DatePipe, registerLocaleData } from '@angular/common';

import { AuthGuard } from './guards/auth.guards';
import { JwtInterceptorProvider } from './helpers/jwt.interceptor';
import { ErrorInterceptorProvider } from './helpers/error.interceptor';
import { AlertService } from './services/alert.service';

// Rutas
import { app_routing } from './app.routes';

import { AppComponent } from './app.component';
import { CookiesComponent } from './components/cookies/cookies.component';
import { HeaderComponent } from './components/header/header.component';
import { IconosComponent } from './components/iconos/iconos.component';
import { FormatocomasPipe } from './pipes/formatocomas.pipe';
import { ProductosComponent } from './components/productos/productos.component';
import { SanitizerPipe } from './pipes/sanitizer.pipe';
import { ProductoItemComponent } from './components/producto-item/producto-item.component';
import { ProductoComponent } from './components/producto/producto.component';
import { CarritoComponent } from './components/carrito/carrito.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { LoginComponent } from './components/login/login.component';
import { PopupComponent } from './components/popup/popup.component';
import { BannerComponent } from './components/banner/banner.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { AvisoComponent } from './components/aviso/aviso.component';
import { FooterComponent } from './components/footer/footer.component';
import { AlertComponent } from './components/alert/alert.component';
import { RespuestaComponent } from './components/respuesta/respuesta.component';
import { RecuperarComponent } from './components/recuperar/recuperar.component';
import { BlogComponent } from './components/blog/blog.component';
import { HomeComponent } from './components/home/home.component';
import { BannerProductosComponent } from './components/banner-productos/banner-productos.component';
import { NosotrosComponent } from './components/nosotros/nosotros.component';
import { CalidadComponent } from './components/calidad/calidad.component';
import { OrigenComponent } from './components/origen/origen.component';

@NgModule({
  declarations: [
    AppComponent,
    CookiesComponent,
    HeaderComponent,
    IconosComponent,
    FormatocomasPipe,
    ProductosComponent,
    SanitizerPipe,
    ProductoItemComponent,
    ProductoComponent,
    CarritoComponent,
    CheckoutComponent,
    LoginComponent,
    PopupComponent,
    BannerComponent,
    ContactoComponent,
    AvisoComponent,
    FooterComponent,
    AlertComponent,
    RespuestaComponent,
    RecuperarComponent,
    BlogComponent,
    HomeComponent,
    BannerProductosComponent,
    NosotrosComponent,
    CalidadComponent,
    OrigenComponent
  ],
  imports: [
    BrowserModule,
BrowserAnimationsModule, // new modules added here
[MatButtonModule, MatCheckboxModule, MatToolbarModule, MatCardModule, MatMenuModule,
MatExpansionModule, MatInputModule, MatTabsModule, MatTooltipModule, MatFormFieldModule,
MatSnackBarModule, MatSelectModule, MatRadioModule, MatListModule],
HttpModule,
HttpClientModule,
FormsModule,
ReactiveFormsModule,
app_routing,
TranslateModule.forRoot({
  provide: TranslateLoader,
  useFactory: function(http: Http) {return new TranslateStaticLoader(http, '/assets/i18n', '.json')},
  deps: [Http]
}),
  ],
  providers: [
  //{ provide: LocationStrategy, useClass: HashLocationStrategy },
  JwtInterceptorProvider,
  ErrorInterceptorProvider,
  AuthGuard,
  AlertService
  //{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
