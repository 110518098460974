import { Component, OnInit, HostListener, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import { DatosService } from '../../services/datos.service';
import { CarritoService } from '../../services/carrito.service';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { AutenticacionService } from '../../services/autenticacion.service';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';


@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
})
export class ProductosComponent implements OnInit {

  slides: any = ['assets/images/productos/slide0.jpg'];
  foto: any = [];
  active: boolean = false;

  espera: number = 6000;
  indexActual:number;
  interval;

  vistaApartado: boolean = false;

  constructor(private _datosService: DatosService) { }

  ngOnInit() {
    this.indexActual = 0;
    this.foto = this.slides[this.indexActual];
    setTimeout(() => { this.active = true; this.getNext(); }, 500);
    this.sacaApartado();
    this._datosService.ponSeccion('productos');
  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 500);
  }

  //FUNCIONES DE SLIDE DE HOME
getNext() {
  this.interval = setInterval (() => {
      if(this.indexActual == this.slides.length - 1) {
        this.indexActual = 0;
      }
      else {
        this.indexActual++;
      }
      this.foto = this.slides[this.indexActual];
  }, this.espera);
}

}
