import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  slides: any = ['assets/images/home/slide0.jpg', 'assets/images/home/slide1.jpg'];
  foto: any = [];
  active: boolean = false;

  espera: number = 6000;
  indexActual:number;
  interval;
  vistaApartado: boolean = false;

  constructor(private _datosService: DatosService) { }

  ngOnInit() {
    this.indexActual = 0;
    this.foto = this.slides[this.indexActual];
    setTimeout(() => { this.active = true; this.getNext(); }, 500);
    this._datosService.ponSeccion('home');
    this.sacaApartado();
  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 500);
  }

  //FUNCIONES DE SLIDE DE HOME
getNext() {
  this.interval = setInterval (() => {
      if(this.indexActual == this.slides.length - 1) {
        this.indexActual = 0;
      }
      else {
        this.indexActual++;
      }
      this.foto = this.slides[this.indexActual];
  }, this.espera);
}

}
