import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';


@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html'
})
export class ContactoComponent implements OnInit {

  constructor(private _datosService:DatosService) { }

  ngOnInit() {
    this._datosService.ponSeccion('home');
  }

}
