import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { TranslateService } from 'ng2-translate';
import { DatosService } from '../app/services/datos.service';
import { Router, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';

import { Meta } from '@angular/platform-browser';


import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';


//Por default dejamos el español
let defaultLanguage = "fr";
//Y como adicional el resto
const additionalLanguages = ["en"];
//Combinamos las traducciones disponibles al default.
const languages: string[] = [defaultLanguage].concat(additionalLanguages);



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./../assets/css/main.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  title = 'Boca';

  constructor(private _datosService: DatosService, private translate: TranslateService, private router: Router, private meta: Meta) {
    this.meta.addTag({ name: 'title', content: 'BOCA UNE ENTREPRISE AVEC UNE VISION D’AVENIR.' });
    this.meta.addTag({ name: 'description', content: 'Nous sommes une entreprise en constante évolution, fournissant des ingrédients biologiques de haute qualité pour l’industrie alimentaire..' });
    this.meta.addTag({ name: 'author', content: 'Euphorbia Comunicación' });
    this.meta.addTag({ name: 'keywords', content: 'Sucre de canne biologique mi blanc, Sucre de canne biologique golden light, Sucre de canne biologique complet, Sucre de canne biologique blanc, Sucre de canne biologique glace, Sucre de canne biologique liquide, Sucre de canne biologique inverti, Mélasse de canne biologique, Moût concentré rectifié biologique' });


  }
  ngOnInit(): void {
    //Asignamos el lenguaje predefinido y los disponibles
    this.translate.setDefaultLang(defaultLanguage);
    this.translate.addLangs(additionalLanguages);
    //Nos basamos en el lenguaje del navegador para cambiar el lenguaje
    let initLang = this.translate.getBrowserLang();
    if (languages.indexOf(initLang) === -1) {
      initLang = defaultLanguage;

    }
    //En base a lo anterior asignamos el lenguaje a usar
    this.translate.use(initLang);

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {

          window.scrollTo(0, 0);
          break;
        }
        default: {
          break;
        }
      }
    });



  }

}
