import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';

@Component({
  selector: 'app-banner-productos',
  templateUrl: './banner-productos.component.html'
})
export class BannerProductosComponent implements OnInit {

  constructor(private _datosService:DatosService) { }

  ngOnInit() {

    console.log(this._datosService.productos);
  }

}
