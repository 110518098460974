import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';

@Component({
  selector: 'app-origen',
  templateUrl: './origen.component.html'
})
export class OrigenComponent implements OnInit {
  slides: any = ['assets/images/paises/brasil.jpg', 'assets/images/paises/colombia.jpg', 'assets/images/paises/india.jpg', 'assets/images/paises/peru.jpg', 'assets/images/paises/tailandia.jpg', 'assets/images/paises/europa.jpg', 'assets/images/paises/costa-rica.jpg', 'assets/images/paises/paraguay.jpg', 'assets/images/paises/filipinas.jpg', 'assets/images/paises/indonesia.jpeg'];
  foto: any = [];
  active: boolean = false;

  espera: number = 6000;
  indexActual: number;
  interval;
  vistaApartado: boolean = false;

  constructor(private _datosService: DatosService) { }

  ngOnInit() {
    this.indexActual = 0;
    this.foto = this.slides[this.indexActual];
    setTimeout(() => { this.active = true; this.getNext(); }, 500);
    this._datosService.ponSeccion('origen');
    this.sacaApartado();
  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 500);
  }
  //FUNCIONES DE SLIDE DE HOME
  getNext() {
    this.interval = setInterval(() => {
      if (this.indexActual == this.slides.length - 1) {
        this.indexActual = 0;
      }
      else {
        this.indexActual++;
      }
      this.foto = this.slides[this.indexActual];
    }, this.espera);
  }

}
